/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum FieldDataType {
    STRING = 'String',
    NUMBER = 'Number',
    DECIMAL = 'Decimal',
    ENUMERATION = 'Enumeration',
    BOOLEAN = 'Boolean',
    DATE_TIME = 'DateTime',
    DATE = 'Date',
    TIME = 'Time',
    REFERENCE = 'Reference',
}