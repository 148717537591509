import { defineStore } from 'pinia'
import { User, Role, OpenAPI, MeService, Permission, UserSetting, FieldSchema } from '@/core/api';
import { ApiExtractErrorAndMessages, ApiShowMessages } from '@/core/common/helper';
import { useMetaStore } from '@/core/common/metaStore';

export type IdentityState = {
    IsAuthenticated: boolean;
    User: User | null;
    Roles: Role[] | null;
    Settings: UserSetting[] | null;
    Permissions: Permission[] | null;
}

export const useIdentityStore = defineStore('identity', {
    state: (): IdentityState => {
        return {
            IsAuthenticated: false,
            Token: null,
            User: null,
            Roles: null,
            Settings: null,
            Permissions: null,
            UserFieldSchemas: [],
            UserEnumerations: [],
        } as IdentityState;
    },

    actions: {
        async setToken(token:string | null){
            if (token){
                const decodedToken = parseJwt(token);
                if (!decodedToken.exp || decodedToken.exp < Date.now() / 1000 ){
                    this.logout();
                    return;
                }

                OpenAPI.TOKEN = token;
                localStorage.setItem('token', token);

                this.IsAuthenticated = await this.reload();
            }
            else
                await this.logout();
        },

        async logout() {
            if (OpenAPI.TOKEN){
                MeService.deleteMeLogin();
            }

            OpenAPI.TOKEN = undefined;

            this.IsAuthenticated = false;
            this.User = null;
            this.Roles = null;
            this.Permissions = null;

            localStorage.removeItem('token');
        },

        UserDisplayName() : string {
            if (this.IsAuthenticated){
                if (this.User?.forename && this.User?.surname){
                    return `${this.User?.forename?.charAt(0)}. ${this.User?.surname}`
                }
                else if (this.User?.surname) {
                    return this.User?.surname;
                }
                else if (this.User?.forename) {
                    return this.User?.forename;
                }
                return this.User?.username!;
            }
            return '';
        },

        HasControllerPermission(entity:string, action:string) : boolean {
            if (entity && action && this.Permissions) {
                const category = 'controller';
                const key = `${entity}controller.${action}`;
                return this.Permissions.findIndex(p => p.category == category && p.key == key) > -1
            }
            return false;
        },

        async reload() : Promise<boolean> {
            await this.loadUser();
            await this.loadSettings();

            await MeService.getMeRoles()
                .then(r => { ApiShowMessages(r.messages!); this.Roles = r.data!; })
                .catch(e => { ApiExtractErrorAndMessages(e); return false; });

            await MeService.getMePermissions()
                .then(r => { ApiShowMessages(r.messages!); this.Permissions = r.data!; })
                .catch(e => { ApiExtractErrorAndMessages(e); return false; });

            return true;
        },

        async loadUser() : Promise<boolean> {
            return await MeService.getMeUser()
                .then(r => { ApiShowMessages(r.messages!); this.User = r.data?.result!; return true; })
                .catch(e => { ApiExtractErrorAndMessages(e); return false; });
        },

        async updateUser(newValues:Array<[string, any]>) : Promise<boolean> {
            // serialize current values
            const meta = useMetaStore();
            const fieldSchemas = (await meta.getFieldSchemas('user'))!;

            const serializedValues = new Array<any[]>();
            for (const [prop, value] of newValues) {
                const schema = fieldSchemas.find(s => s.property == prop.toLowerCase())!;
                const serializedValue = await meta.serializeEntityValue(schema, value)
                serializedValues.push([prop, serializedValue]);
            }

            // send new values to server
            if (! await MeService.putMeUser(Object.fromEntries(serializedValues))
                    .then(r => { ApiShowMessages(r.messages!); return r.data; })
                    .catch(e => { ApiExtractErrorAndMessages(e); return false; }))
                return false;

            // reload user data from server
            return await this.loadUser();
        },

        async loadSettings() : Promise<boolean> {
            return await MeService.getMeSettings('web')
                .then(r => { ApiShowMessages(r.messages!); this.Settings = r.data?.result!; return true;})
                .catch(e => { ApiExtractErrorAndMessages(e); return false; });
        },

        async updateSettings(newValues:Array<[string, string]>) : Promise<boolean> {
            for (const [key, value] of newValues) {
                if (! await MeService.postMeSettings('web', key, value)
                    .then(r => { ApiShowMessages(r.messages!); return r.data; })
                    .catch(e => { ApiExtractErrorAndMessages(e); return false; }))
                return false;
            }

            return await this.loadSettings();
        },
    }
});


function parseJwt (token:string | undefined | null): any {
    if (token && token.split('.').length == 3){
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    return undefined
};

