/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BooleanGenericResponse } from '../models/BooleanGenericResponse';
import type { Int32GenericResponse } from '../models/Int32GenericResponse';
import type { UserRoleEntityResponseGenericResponse } from '../models/UserRoleEntityResponseGenericResponse';
import type { UserRoleIEnumerableEntityResponseGenericResponse } from '../models/UserRoleIEnumerableEntityResponseGenericResponse';
import type { UserRoleQueryResultEntityResponseGenericResponse } from '../models/UserRoleQueryResultEntityResponseGenericResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserRoleService {

    /**
     * @param requestBody 
     * @returns Int32GenericResponse Success
     * @throws ApiError
     */
    public static postUserrole(
requestBody?: Record<string, string>,
): CancelablePromise<Int32GenericResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/userrole',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param page 
     * @param pageSize 
     * @param rawQuery 
     * @param orderDesc 
     * @param orderBy 
     * @param resolveRefs 
     * @returns UserRoleQueryResultEntityResponseGenericResponse Success
     * @throws ApiError
     */
    public static getUserrole(
page?: number,
pageSize?: number,
rawQuery?: Record<string, Array<string>>,
orderDesc?: boolean,
orderBy?: string,
resolveRefs?: boolean,
): CancelablePromise<UserRoleQueryResultEntityResponseGenericResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/userrole',
            query: {
                'page': page,
                'pageSize': pageSize,
                'rawQuery': rawQuery,
                'orderDesc': orderDesc,
                'orderBy': orderBy,
                'resolveRefs': resolveRefs,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns BooleanGenericResponse Success
     * @throws ApiError
     */
    public static putUserrole(
id: number,
requestBody?: Record<string, string>,
): CancelablePromise<BooleanGenericResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/userrole/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param id 
     * @returns BooleanGenericResponse Success
     * @throws ApiError
     */
    public static deleteUserrole(
id: number,
): CancelablePromise<BooleanGenericResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/userrole/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param id 
     * @param resolveRefs 
     * @returns UserRoleEntityResponseGenericResponse Success
     * @throws ApiError
     */
    public static getUserrole1(
id: number,
resolveRefs?: boolean,
): CancelablePromise<UserRoleEntityResponseGenericResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/userrole/{id}',
            path: {
                'id': id,
            },
            query: {
                'resolveRefs': resolveRefs,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param ids 
     * @param resolveRefs 
     * @returns UserRoleIEnumerableEntityResponseGenericResponse Success
     * @throws ApiError
     */
    public static getUserroleSelect(
ids?: Array<number>,
resolveRefs?: boolean,
): CancelablePromise<UserRoleIEnumerableEntityResponseGenericResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/userrole/select',
            query: {
                'ids': ids,
                'resolveRefs': resolveRefs,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

}