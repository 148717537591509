/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FieldDataTypeCharArrayIReadOnlyDictionaryGenericResponse } from '../models/FieldDataTypeCharArrayIReadOnlyDictionaryGenericResponse';
import type { StringEnumerationIEnumerableIDictionaryGenericResponse } from '../models/StringEnumerationIEnumerableIDictionaryGenericResponse';
import type { StringFieldSchemaIEnumerableIDictionaryGenericResponse } from '../models/StringFieldSchemaIEnumerableIDictionaryGenericResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MetaService {

    /**
     * @returns FieldDataTypeCharArrayIReadOnlyDictionaryGenericResponse Success
     * @throws ApiError
     */
    public static getMetaModifier(): CancelablePromise<FieldDataTypeCharArrayIReadOnlyDictionaryGenericResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/meta/modifier',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param entities 
     * @returns StringFieldSchemaIEnumerableIDictionaryGenericResponse Success
     * @throws ApiError
     */
    public static getMetaSchemaSelect(
entities?: Array<string>,
): CancelablePromise<StringFieldSchemaIEnumerableIDictionaryGenericResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/meta/schema/select',
            query: {
                'entities': entities,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param names 
     * @returns StringEnumerationIEnumerableIDictionaryGenericResponse Success
     * @throws ApiError
     */
    public static getMetaEnumSelect(
names?: Array<string>,
): CancelablePromise<StringEnumerationIEnumerableIDictionaryGenericResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/meta/enum/select',
            query: {
                'names': names,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}