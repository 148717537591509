/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BooleanGenericResponse } from '../models/BooleanGenericResponse';
import type { Int32GenericResponse } from '../models/Int32GenericResponse';
import type { UserEntityResponseGenericResponse } from '../models/UserEntityResponseGenericResponse';
import type { UserIEnumerableEntityResponseGenericResponse } from '../models/UserIEnumerableEntityResponseGenericResponse';
import type { UserQueryResultEntityResponseGenericResponse } from '../models/UserQueryResultEntityResponseGenericResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * @param id 
     * @param requestBody 
     * @returns BooleanGenericResponse Success
     * @throws ApiError
     */
    public static putUser(
id: number,
requestBody?: Record<string, string>,
): CancelablePromise<BooleanGenericResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/user/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param id 
     * @returns BooleanGenericResponse Success
     * @throws ApiError
     */
    public static deleteUser(
id: number,
): CancelablePromise<BooleanGenericResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param id 
     * @param resolveRefs 
     * @returns UserEntityResponseGenericResponse Success
     * @throws ApiError
     */
    public static getUser(
id: number,
resolveRefs?: boolean,
): CancelablePromise<UserEntityResponseGenericResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/{id}',
            path: {
                'id': id,
            },
            query: {
                'resolveRefs': resolveRefs,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns BooleanGenericResponse Success
     * @throws ApiError
     */
    public static postUserPassword(
id: number,
requestBody?: string,
): CancelablePromise<BooleanGenericResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/{id}/password',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param id 
     * @returns BooleanGenericResponse Success
     * @throws ApiError
     */
    public static deleteUserLogout(
id: number,
): CancelablePromise<BooleanGenericResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user/{id}/logout',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Int32GenericResponse Success
     * @throws ApiError
     */
    public static postUser(
requestBody?: Record<string, string>,
): CancelablePromise<Int32GenericResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param page 
     * @param pageSize 
     * @param rawQuery 
     * @param orderDesc 
     * @param orderBy 
     * @param resolveRefs 
     * @returns UserQueryResultEntityResponseGenericResponse Success
     * @throws ApiError
     */
    public static getUser1(
page?: number,
pageSize?: number,
rawQuery?: Record<string, Array<string>>,
orderDesc?: boolean,
orderBy?: string,
resolveRefs?: boolean,
): CancelablePromise<UserQueryResultEntityResponseGenericResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user',
            query: {
                'page': page,
                'pageSize': pageSize,
                'rawQuery': rawQuery,
                'orderDesc': orderDesc,
                'orderBy': orderBy,
                'resolveRefs': resolveRefs,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param ids 
     * @param resolveRefs 
     * @returns UserIEnumerableEntityResponseGenericResponse Success
     * @throws ApiError
     */
    public static getUserSelect(
ids?: Array<number>,
resolveRefs?: boolean,
): CancelablePromise<UserIEnumerableEntityResponseGenericResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/select',
            query: {
                'ids': ids,
                'resolveRefs': resolveRefs,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

}