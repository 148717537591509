export type ViewMenuGroup = {
    label: string,
    items: (ViewMenuItem | ViewMenuGroup)[],
    icon?: string
    hidden?: boolean,
    order?: number,
    enabled?: boolean,
};

export type ViewMenuItem = {
    isExecuting: boolean,
    label: string,
    icon: string,
    action: () => Promise<any>,
    order?: number,
    enabled?: boolean,
    hidden?: boolean,
    confirm?: boolean,
};

export interface IMenuGroupParameters{
    items: (ViewMenuItem | ViewMenuGroup)[],
    icon?: string,
    label?: string,
    hidden?: boolean,
    order?: number,
    enabled?: boolean,
};

export interface IMenuActionParameters{
    label?: string,
    icon?: string,
    action?: () => Promise<any>,
    order?: number,
    hidden?: boolean,
    enabled?: boolean,
    confirm?: boolean,
};

export class MenuGroups{
    public static generic(params:IMenuGroupParameters): ViewMenuGroup {
        return ({
            label: params.label,
            icon: params.icon,
            items: params.items,
            hidden: params.hidden,
            order: params.order,
            enabled: params.enabled,
        }) as ViewMenuGroup;
    }
}

export class MenuActions{
    public static generic(params:IMenuActionParameters): ViewMenuItem {
        return ({
            isExecuting: false,
            label: params.label,
            icon: params.icon,
            action: params.action,
            order: params.order,
            enabled: params.enabled,
            hidden: params.hidden,
            confirm: params.confirm,
        }) as ViewMenuItem;
    }

    public static open(params:IMenuActionParameters): ViewMenuItem {
        return ({
            isExecuting: false,
            label: params.label ?? 'menu.action.open',
            icon: params.icon ?? 'pi-folder-open',
            action: params.action,
            order: params.order,
            enabled: params.enabled,
            hidden: params.hidden,
            confirm: params.confirm,
        }) as ViewMenuItem;
    }

    public static select(params:IMenuActionParameters): ViewMenuItem {
        return ({
            isExecuting: false,
            label: params.label ?? 'menu.action.select',
            icon: params.icon ?? 'pi-arrow-down-left',
            action: params.action,
            order: params.order,
            enabled: params.enabled,
            hidden: params.hidden,
            confirm: params.confirm,
        }) as ViewMenuItem;
    }

    public static add(params:IMenuActionParameters): ViewMenuItem {
        return ({
            isExecuting: false,
            label: params.label ?? 'menu.action.add',
            icon: params.icon ?? 'pi-plus',
            action: params.action,
            order: params.order,
            enabled: params.enabled,
            hidden: params.hidden,
            confirm: params.confirm,
        }) as ViewMenuItem;
    }

    public static copy(params:IMenuActionParameters): ViewMenuItem {
        return ({
            isExecuting: false,
            label: params.label ?? 'menu.action.copy',
            icon: params.icon ?? 'pi-copy',
            action: params.action,
            order: params.order,
            enabled: params.enabled,
            hidden: params.hidden,
            confirm: params.confirm,
        }) as ViewMenuItem;
    }

    public static save(params:IMenuActionParameters): ViewMenuItem {
        return ({
            isExecuting: false,
            label: params.label ?? 'menu.action.save',
            icon: params.icon ?? 'pi-save',
            action: params.action,
            order: params.order,
            enabled: params.enabled,
            hidden: params.hidden,
            confirm: params.confirm,
        }) as ViewMenuItem;
    }

    public static saveAndOpen(params:IMenuActionParameters): ViewMenuItem {
        return ({
            isExecuting: false,
            label: params.label ?? 'menu.action.saveandopen',
            icon: params.icon ?? 'pi-folder-open',
            action: params.action,
            order: params.order,
            enabled: params.enabled,
            hidden: params.hidden,
            confirm: params.confirm,
        }) as ViewMenuItem;
    }

    public static reset(params:IMenuActionParameters): ViewMenuItem {
        return ({
            isExecuting: false,
            label: params.label ?? 'menu.action.reset',
            icon: params.icon ?? 'pi-replay',
            action: params.action,
            order: params.order,
            enabled: params.enabled,
            hidden: params.hidden,
            confirm: params.confirm,
        }) as ViewMenuItem;
    }

    public static remove(params:IMenuActionParameters): ViewMenuItem {
        return ({
            isExecuting: false,
            label: params.label ?? 'menu.action.remove',
            icon: params.icon ?? 'pi-trash',
            action: params.action,
            order: params.order,
            enabled: params.enabled,
            hidden: params.hidden,
            confirm: params.confirm ?? true,
        }) as ViewMenuItem;
    }

    public static refresh(params:IMenuActionParameters): ViewMenuItem {
        return ({
            isExecuting: false,
            label: params.label ?? 'menu.action.refresh',
            icon: params.icon ?? 'pi-refresh',
            action: params.action,
            order: params.order,
            enabled: params.enabled,
            hidden: params.hidden,
            confirm: params.confirm,
        }) as ViewMenuItem;
    }
}
