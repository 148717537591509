import { defineStore } from "pinia";
import { FieldSchema, Enumeration, MetaService, FieldDataType, User, Role, Permission } from "@/core/api";
import { ApiExtractErrorAndMessages, ApiShowMessages } from "@/core/common/helper";
import { TimeSpan } from "@/core/common/utlis/TimeSpan";


export type metaState = {
    isReady: Boolean,
    schemas: Map<string, FieldSchema[]>,
    enums: Map<string, Enumeration[]>,
    labels: Record<string, string>,
    modifiers: Map<FieldDataType, string[]>,
    entityPreviews: Map<string, (data:any)=>Promise<string>>,
};

export const useMetaStore = defineStore('meta', {
    state: (): metaState => {
        const init = <metaState>{
            isReady: false,
            schemas: new Map(),
            enums: new Map(),
            labels: {},
            modifiers: new Map(),
            entityPreviews: new Map(),
        };

        return init;
    },

    actions: {
        async init() {
            await fetch('/labels/de-de/core.json')
                .then(response => response.json())
                .then(data => this.labels = {...this.labels, ...data});

            await fetch('/labels/de-de/business.json')
                .then(response => response.json())
                .then(data => this.labels = {...this.labels, ...data});

            await MetaService.getMetaModifier()
                .then(response => {
                    ApiShowMessages(response.messages!);
                    Object.keys(response.data!).forEach(key => {
                        this.modifiers.set(key as FieldDataType, response.data![key])
                    });
                });

            this.isReady = true;
        },

        getLabel(key:string):string {
            key = key?.toLowerCase() ?? '';
            const label = this.labels[key];
            return label ? label : `Missing label: '${key}'`;
        },

        async getFieldSchemas(entity:string) : Promise<FieldSchema[] | undefined> {
            entity = entity.toLowerCase();

            // try get it from cache
            const items = this.schemas.get(entity);
            if (items)
                return items;

            // try get it from server, cache it, return it
            return await MetaService.getMetaSchemaSelect([entity])
                .then(response => {
                    ApiShowMessages(response.messages!);
                    for (const entityName in response.data!){
                        this.schemas.set(entityName, response.data[entityName].sort(FieldSchemaOrderComparer));
                    }
                    return this.schemas.get(entity);
                })
                .catch(response => {
                    ApiExtractErrorAndMessages(response);
                    return Promise.reject(undefined);
                });
        },

        async getEnumeration(name:string) : Promise<Enumeration[] | undefined> {
            name = name.toLowerCase();

            // try get it from cache
            const items = this.enums.get(name);
            if (items)
                return items;

            // try get it from server, cache it, return it
            return await MetaService.getMetaEnumSelect([name])
                .then(response => {
                    ApiShowMessages(response.messages!);
                    for (const enumName in response.data!){
                        this.enums.set(enumName, response.data[enumName]);
                    }
                    return this.enums.get(name);
                })
                .catch(response => {
                    ApiExtractErrorAndMessages(response);
                    return Promise.reject(undefined);
                });
        },

        async serializeEntityValue(schema:FieldSchema, value:any) : Promise<string | null> {
            if (value == null || value == undefined){
                return null;
            }

            else if (schema.dataType == FieldDataType.ENUMERATION) {
                const enumeration = await this.getEnumeration(schema.dataTypeName!);
                if (enumeration) {
                    return enumeration.find(e => e.entryName == value.toLowerCase())?.entryId?.toString() ?? null;
                }
            }

            else if (schema.dataType == FieldDataType.DATE_TIME || schema.dataType == FieldDataType.DATE) {
                if (schema.dataType == FieldDataType.DATE) {
                    (value as Date).setHours(0);
                    (value as Date).setMinutes(0);
                }
                (value as Date).setSeconds(0);
                (value as Date).setMilliseconds(0);

                return value.toISOString();
            }

            return value.toString();
        },

        async deserializeEntityValue(schema:FieldSchema, value:any) : Promise<any | undefined> {
            if (value == null || value == undefined){
                return null;
            }
            else if (schema.dataType == FieldDataType.NUMBER) {
                return parseInt(value, 10);
            }
            else if (schema.dataType == FieldDataType.DECIMAL) {
                return parseFloat(value);
            }
            else if (schema.dataType == FieldDataType.BOOLEAN) {
                return value == true || value == 'true';
            }
            else if (schema.dataType == FieldDataType.TIME) {
                return TimeSpan.parse(value);
            }
            else if (schema.dataType == FieldDataType.DATE_TIME || schema.dataType == FieldDataType.DATE) {
                return new Date(value);
            }
            else if (schema.dataType == FieldDataType.ENUMERATION) {
                const intValue = parseInt(value, 10);
                const enumeration = (await this.getEnumeration(schema.dataTypeName!))!;
                if (intValue){
                    return enumeration.find(e => e.entryId == intValue)?.entryName!;
                }
                else {
                    return enumeration.find(e => e.entryName == value.toLowerCase())?.entryName!;
                }
            }
            else {
                return value;
            }
        },

        async getEntityPreview(entity:string, data:any) : Promise<string | undefined> {
            const explicitPreview = this.entityPreviews.get(entity);
            if (explicitPreview){
                return await explicitPreview(data);
            }
            else{
                var dataEntries = Object.entries(data);
                let fields = await this.getFieldSchemas(entity);
                if (fields){
                    fields = fields.filter(f => f.dataType == FieldDataType.STRING);
                    const desciptiveField = fields.find(f =>
                        f.property?.includes('name') ||
                        f.property?.includes('description') ||
                        f.property?.includes('note')
                    ) ??  fields.length > 0 ? fields[0] : undefined;
                    const desciptiveValue = dataEntries.find(([prop, _]) => prop.toLowerCase() == desciptiveField?.property)?.[1];
                    return desciptiveValue ? desciptiveValue as string : undefined;
                }

                return undefined;
            }
        }
    },
});


function FieldSchemaOrderComparer(a: FieldSchema, b: FieldSchema) : number {
    const result = a.order! - b.order!;
    if (result != 0){ return result }
    else {
        if (a.property == 'createdate' || a.property == 'createuser') { return 1; }
        else if (b.property == 'createdate' || b.property == 'createuser') { return -1; }

        if (a.isKey == b.isKey) {
            if (a.isReadOnly == b.isReadOnly) {
                if (a.isRequired == b.isRequired) {
                    return a.property! < b.property! ? -1 : 1;
                }
                else {
                    return a.isRequired && !b.isRequired ? -1 : 1;
                }
            }
            else {
                return a.isReadOnly && !b.isReadOnly ? 1 : -1;
            }
        }
        else {
            return a.isKey && !b.isKey ? -1 : 1;
        }
    }
}
