// For any reason, using compound imports inside @/api for anything else that types did not work, even when
// the configuration was adjusted to add the generated folder to the sources path.
// Therefore we use this wrapper with relative paths to avoid this error entirely.
// The api should be accessed only through this file.
//
// NOTE There should be no import containing 'generated' elsewhere than in this file

import { GenericDetailResponse } from '@/core/common/Types';
import { resolveComponent } from 'vue';
import { OpenAPI } from '../../generated/api';
import { request } from '../../generated/api/core/request';
import { ApiShowMessages, ApiExtractErrorAndMessages } from './common/helper';

export * from '../../generated/api';
export { request, getQueryString } from '../../generated/api/core/request';


export async function GetEntity(entity:string, id:number, resolveRefs:boolean = false) : Promise<GenericDetailResponse | undefined> {
    return new Promise(async function(resolve, reject) {
        if (!entity || !id || id < 1){
            return undefined;
        }

        await request<GenericDetailResponse>(OpenAPI, {
            method: 'GET',
            url: `/${entity}/${id}`,
            query: { 'resolveRefs': resolveRefs },
        })
            .then(response => {
                ApiShowMessages(response.messages!);
                resolve(response);
            })
            .catch(response => {
                ApiExtractErrorAndMessages(response);
                reject(undefined);
            });
    });
}


