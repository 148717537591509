import { defineStore } from "pinia";
import { ViewInfo } from "@/core/common/Types";

export type indexState = {
    views: ViewInfo[],
};

export const useZIndexStore = defineStore('view', {
    state: (): indexState => {
        const init = <indexState>{
            views: [],
        };

        return init;
    },

    actions: {
        push(view:ViewInfo) {
            this.views.push(view);
        },

        pop(root:HTMLElement) {
            const view = this.views.find(v => v.root == root);
            if (view){
                this.views.splice(this.views.indexOf(view), 1);
            }
        },

        isTop(root:HTMLElement) : boolean {
            const view = this.views.find(v => v.root == root);
            if (view && this.views.length > 0){
                return this.views[this.views.length - 1] == view;
            }

            return false;
        },

        getIndex(root:HTMLElement) : number {
            const view = this.views.find(v => v.root == root);
            if (view && this.views.length > 0){
                return this.views.indexOf(view) * 2 + 1;
            }

            return -1;
        },

        topIndex() :number {
            if (this.views.length > 0){
                this.views.length * 2 + 1;
            }

            return -1;
        },

        first() : ViewInfo | null {
            return this.views.length > 0 ? this.views[0] : null;
        }
    }
});
