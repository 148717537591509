import { OpenAPI } from '@/core/api';
OpenAPI.BASE = process.env.VUE_APP_FRONTEND_API_PROXY_URL;

import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.min.css';
import 'primeicons/primeicons.css';
import './assets/style.css';

import { createApp } from "vue";
import { createPinia } from 'pinia'
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';

import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';
import BadgeDirective from 'primevue/badgedirective';
import Tooltip from 'primevue/tooltip';

import App from "./business/App.vue";
import { appRouter } from './routing';


const app = createApp(App);

app.use(appRouter);
app.use(PrimeVue, {ripple: true});
app.use(ToastService);
app.use(ConfirmationService);
app.use(createPinia());

app.directive('ripple', Ripple);
app.directive('styleclass', StyleClass);
app.directive('badge', BadgeDirective);
app.directive('tooltip', Tooltip);

app.mount("#app");
