/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BooleanGenericResponse } from '../models/BooleanGenericResponse';
import type { Int32GenericResponse } from '../models/Int32GenericResponse';
import type { RolePermissionEntityResponseGenericResponse } from '../models/RolePermissionEntityResponseGenericResponse';
import type { RolePermissionIEnumerableEntityResponseGenericResponse } from '../models/RolePermissionIEnumerableEntityResponseGenericResponse';
import type { RolePermissionQueryResultEntityResponseGenericResponse } from '../models/RolePermissionQueryResultEntityResponseGenericResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RolePermissionService {

    /**
     * @param requestBody 
     * @returns Int32GenericResponse Success
     * @throws ApiError
     */
    public static postRolepermission(
requestBody?: Record<string, string>,
): CancelablePromise<Int32GenericResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/rolepermission',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param page 
     * @param pageSize 
     * @param rawQuery 
     * @param orderDesc 
     * @param orderBy 
     * @param resolveRefs 
     * @returns RolePermissionQueryResultEntityResponseGenericResponse Success
     * @throws ApiError
     */
    public static getRolepermission(
page?: number,
pageSize?: number,
rawQuery?: Record<string, Array<string>>,
orderDesc?: boolean,
orderBy?: string,
resolveRefs?: boolean,
): CancelablePromise<RolePermissionQueryResultEntityResponseGenericResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rolepermission',
            query: {
                'page': page,
                'pageSize': pageSize,
                'rawQuery': rawQuery,
                'orderDesc': orderDesc,
                'orderBy': orderBy,
                'resolveRefs': resolveRefs,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns BooleanGenericResponse Success
     * @throws ApiError
     */
    public static putRolepermission(
id: number,
requestBody?: Record<string, string>,
): CancelablePromise<BooleanGenericResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/rolepermission/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param id 
     * @returns BooleanGenericResponse Success
     * @throws ApiError
     */
    public static deleteRolepermission(
id: number,
): CancelablePromise<BooleanGenericResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/rolepermission/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param id 
     * @param resolveRefs 
     * @returns RolePermissionEntityResponseGenericResponse Success
     * @throws ApiError
     */
    public static getRolepermission1(
id: number,
resolveRefs?: boolean,
): CancelablePromise<RolePermissionEntityResponseGenericResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rolepermission/{id}',
            path: {
                'id': id,
            },
            query: {
                'resolveRefs': resolveRefs,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param ids 
     * @param resolveRefs 
     * @returns RolePermissionIEnumerableEntityResponseGenericResponse Success
     * @throws ApiError
     */
    public static getRolepermissionSelect(
ids?: Array<number>,
resolveRefs?: boolean,
): CancelablePromise<RolePermissionIEnumerableEntityResponseGenericResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rolepermission/select',
            query: {
                'ids': ids,
                'resolveRefs': resolveRefs,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

}