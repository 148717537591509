import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import NotFound from '@/core/common/view/NotFound.vue'
import Dashboard from '@/core/common/view/Dashboard.vue'
import UserSettings from '@/core/identity/UserSettings.vue'

function createRoutes():RouteRecordRaw[]{
  const entities = [
    ...([
      'User',
      'UserRole',
      'UserPermission',
      'Role',
      'RolePermission',
      'Permission',
      'UserSetting'
    ].map(f => ({name:f, category:'core/identity'}))),

    ...([
      'Excipient',
      'ExcipientDelivery',
      'ExcipientDeliveryUse',
      'Product',
      'ProductDelivery',
      'ProductDeliveryUse',
      'Silo',
      'SiloDelivery',
      'Supplier',
      'Tank',
      'Machine',
      'Cleaning',
      'Maintenance',
    ].map(f => ({name:f, category:'business/inventory'}))),

    ...([
      'Analysis',
      'AppleJuiceRefinement',
      'AppleWineFermentation',
      'StorageUse',
      'Storage',
      'StorageInput',
      'Blending',
      'PressJuice',
    ].map(f => ({name:f, category:'business/production'}))),
  ];

  const routes = [
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
    { path: '/', component: Dashboard },
    { path: '/settings', component: UserSettings}
  ];

  for (const entity of entities){
    // @ts-ignore
    routes.push({path: `/${entity.name.toLocaleLowerCase()}`, component: ()=>import(`@/${entity.category}/${entity.name}Search.vue`)});
    // @ts-ignore
    routes.push({path: `/${entity.name.toLocaleLowerCase()}/:id`, component: ()=>import(`@/${entity.category}/${entity.name}Detail.vue`)});  }

  return routes;
}

export const appRouter = createRouter({history: createWebHistory(), routes: createRoutes()});
