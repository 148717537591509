import { RRMessage, ApiError, GenericResponse, FieldSchema, RRMessageType } from "@/core/api";
import { useIdentityStore } from "@/core/identity/identityStore";
import { useMetaStore } from "@/core/common/metaStore";
import { SearchFieldClause } from "@/core/common/Types";

export const nameof = <T>(name: keyof T) => name;

export function hasValue(value:any) : boolean {
    if (value === undefined || value === null) { return false; }
    if (typeof value === 'string' || value instanceof String) { return !!value; }
    return true;
}

export function ApiExtractErrorAndMessages(error:any): [ApiError | null, RRMessage[]] {
    const apiError = error as ApiError;
    const response = apiError.body as GenericResponse;

    if (apiError.status == 401){
        const identity = useIdentityStore();
        identity.logout();
    }
    const extractdError = apiError.status != 400 ? apiError : null;
    const extractedMessages = response && response.messages ? response.messages : new Array();

    ApiShowMessages(extractedMessages);
    return [extractdError, extractedMessages];
}

export function ApiShowMessages(messages: RRMessage[]) {
    //const toast = useToast();
    const meta = useMetaStore();

    for(var message of messages){
        const life = 10000;
        const detail = meta.getLabel(message.id!);

        let severity = 'info';
        if (message.messageType == RRMessageType.WARNING){
            severity = 'warn';
        }
        else if (message.messageType == RRMessageType.ERROR){
            severity = 'error';
        }

        console.log({severity: severity, summary: message.source!, detail: detail, life: life});
        //toast.add({severity: severity, summary: message.source!, detail: detail, life: life});
    }
}

export async function deserializeClausesFromQuery(fieldSchemas:FieldSchema[], query:any) : Promise<Map<string, SearchFieldClause[]>> {
    const meta = useMetaStore();
    const result = new Map<string, SearchFieldClause[]>();

    async function toClause(schema:FieldSchema, value:any): Promise<SearchFieldClause> {
        const modifier = value!.substring(0, 1);
        const typedValue = await meta.deserializeEntityValue(schema, value?.substring(1));
        return ({modifier: modifier, value: typedValue}) as SearchFieldClause
    };

    for(const [key, clause] of Object.entries(query)){
        const schema = fieldSchemas.find(f => f.property! == key)!;
        if(Array.isArray(clause)) {
            const clauses = await Promise.all(clause.map(c => toClause(schema, c)));
            result.set(key, clauses);
        }
        else {
            result.set(key, [await toClause(schema, clause)]);
        }
    }

    return result;
}

export async function serializeClausesToQuery(fieldSchemas:FieldSchema[], fieldClauses:Map<string, SearchFieldClause[]>) : Promise<Map<string, string[]>> {
    const result = new Map<string, string[]>();
    if (fieldClauses != null){
        const meta = useMetaStore();
        for(const [key, clauses] of fieldClauses){
            if (clauses.length > 0){
                const schema = fieldSchemas.find(f => f.property! == key)!;
                result.set(key, await Promise.all(clauses.map(async (c) => c.modifier + await meta.serializeEntityValue(schema, c.value))));
            }
        }
    }

    return result;
}


export async function serializeOrderToQuery(order:[string | undefined, "asc" | "desc" | undefined]): Promise<Map<string, string>>{
    const result = new Map<string, string>();
    if (order[0] != undefined){
        result.set('orderBy', order[0]);
        if (order[1] == 'desc'){
            result.set('orderDesc', 'true');
        }
    }
    return result;
}

export async function deserializeOrderFromQuery(query:any): Promise<[string | undefined, "asc" | "desc" | undefined]>{
    const result:[string | undefined, "asc" | "desc" | undefined] = [undefined, undefined];

    if ('orderBy' in query){
        result[0] = query['orderBy'];
        result[1] = 'asc';
    }
    delete query['orderBy'];

    if ('orderDesc' in query){
        result[1] = 'desc';
    }
    delete query['orderDesc'];

    return result;
}
