import { defineStore } from "pinia";

export type themeState = {
    themes: ThemeCategory[],
    default: string,
};

export type ThemeItem = {
    label: string,
    value: string,
}

export type ThemeCategory = {
    label: string,
    items: ThemeItem[],
}

export const useThemeStore = defineStore('theme', {
    state: (): themeState => {
        const init = <themeState>{
            default: 'vela-blue',
            themes: [
                {
                    label: 'Bootstrap4',
                    items: [
                        { label: 'Light Blue', value: 'bootstrap4-light-blue' },
                        { label: 'Light Purple', value: 'bootstrap4-light-purple' },
                        { label: 'Dark Blue', value: 'bootstrap4-dark-blue' },
                        { label: 'Dark Purple', value: 'bootstrap4-dark-purple' },
                    ]
                },
                {
                    label: 'MaterialDesign',
                    items: [
                        { label: 'Light Indigo', value: 'md-light-indigo' },
                        { label: 'Light Deeppurple', value: 'md-light-deeppurple' },
                        { label: 'Dark Indigo', value: 'md-dark-indigo' },
                        { label: 'Dark Deeppurple', value: 'md-dark-deeppurple' },
                    ]
                },
                {
                    label: 'MDC',
                    items: [
                        { label: 'Light Indigo', value: 'mdc-light-indigo' },
                        { label: 'Light Deeppurple', value: 'mdc-light-deeppurple' },
                        { label: 'Dark Indigo', value: 'mdc-dark-indigo' },
                        { label: 'Dark Deeppurple', value: 'mdc-dark-deeppurple' },
                    ]
                },
                {
                    label: 'Lara',
                    items: [
                        { label: 'Light Indigo', value: 'lara-light-indigo' },
                        { label: 'Dark Indigo', value: 'lara-dark-indigo' },
                        { label: 'Light Purple', value: 'lara-light-purple' },
                        { label: 'Dark Purple', value: 'lara-dark-purple' },
                        { label: 'Light Blue', value: 'lara-light-blue' },
                        { label: 'Dark Blue', value: 'lara-dark-blue' },
                        { label: 'Light Teal', value: 'lara-light-teal' },
                        { label: 'Dark Teal', value: 'lara-dark-teal' },
                    ]
                },
                {
                    label: 'Saga',
                    items: [
                        { label: 'Blue', value: 'saga-blue' },
                        { label: 'Green', value: 'saga-green' },
                        { label: 'Orange', value: 'saga-orange' },
                        { label: 'Purple', value: 'saga-purple' },
                    ]
                },
                {
                    label: 'Vela',
                    items: [
                        { label: 'Blue', value: 'vela-blue' },
                        { label: 'Green', value: 'vela-green' },
                        { label: 'Orange', value: 'vela-orange' },
                        { label: 'Purple', value: 'vela-purple' },
                    ]
                },
                {
                    label: 'Arya',
                    items: [
                        { label: 'Blue', value: 'arya-blue' },
                        { label: 'Green', value: 'arya-green' },
                        { label: 'Orange', value: 'arya-orange' },
                        { label: 'Purple', value: 'arya-purple' },
                    ]
                },
                {
                    label: 'Nova',
                    items: [
                        { label: 'Default', value: 'nova' },
                        { label: 'Alt', value: 'nova-alt' },
                        { label: 'Accent', value: 'nova-accent' },
                        { label: 'Vue', value: 'nova-vue' },
                    ]
                },
                {
                    label: 'Luna',
                    items: [
                        { label: 'Amber', value: 'luna-amber' },
                        { label: 'Blue', value: 'luna-blue' },
                        { label: 'Green', value: 'luna-green' },
                        { label: 'Pink', value: 'luna-pink' },
                    ]
                },
                {
                    label: 'Other',
                    items: [
                        { label: 'Tailwind Light', value: 'tailwind-light' },
                        { label: 'Fluent Light', value: 'fluent-light' },
                        { label: 'Rhea', value: 'rhea' },
                    ]
                },
            ],
        };

        return init;
    },

    actions: {
        changeTo(theme:string) {
            let themeElement = document.getElementById('theme-link');
            let themeLocation = '/themes/' + theme + '/theme.css'
            themeElement?.setAttribute('href', themeLocation);
        }
    },
});
