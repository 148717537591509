/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BooleanGenericResponse } from '../models/BooleanGenericResponse';
import type { MeLoginData } from '../models/MeLoginData';
import type { MePasswordUpdateData } from '../models/MePasswordUpdateData';
import type { PermissionIEnumerableGenericResponse } from '../models/PermissionIEnumerableGenericResponse';
import type { RoleIEnumerableGenericResponse } from '../models/RoleIEnumerableGenericResponse';
import type { StringGenericResponse } from '../models/StringGenericResponse';
import type { UserEntityResponseGenericResponse } from '../models/UserEntityResponseGenericResponse';
import type { UserSettingEntityResponseGenericResponse } from '../models/UserSettingEntityResponseGenericResponse';
import type { UserSettingIEnumerableEntityResponseGenericResponse } from '../models/UserSettingIEnumerableEntityResponseGenericResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MeService {

    /**
     * @param requestBody 
     * @returns StringGenericResponse Success
     * @throws ApiError
     */
    public static postMeLogin(
requestBody?: MeLoginData,
): CancelablePromise<StringGenericResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/me/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns BooleanGenericResponse Success
     * @throws ApiError
     */
    public static deleteMeLogin(): CancelablePromise<BooleanGenericResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/me/login',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @returns UserEntityResponseGenericResponse Success
     * @throws ApiError
     */
    public static getMeUser(): CancelablePromise<UserEntityResponseGenericResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/me/user',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns BooleanGenericResponse Success
     * @throws ApiError
     */
    public static putMeUser(
requestBody?: Record<string, string>,
): CancelablePromise<BooleanGenericResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/me/user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @returns RoleIEnumerableGenericResponse Success
     * @throws ApiError
     */
    public static getMeRoles(): CancelablePromise<RoleIEnumerableGenericResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/me/roles',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns PermissionIEnumerableGenericResponse Success
     * @throws ApiError
     */
    public static getMePermissions(): CancelablePromise<PermissionIEnumerableGenericResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/me/permissions',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param app 
     * @returns UserSettingIEnumerableEntityResponseGenericResponse Success
     * @throws ApiError
     */
    public static getMeSettings(
app: string,
): CancelablePromise<UserSettingIEnumerableEntityResponseGenericResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/me/settings/{app}',
            path: {
                'app': app,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param app 
     * @param key 
     * @returns UserSettingEntityResponseGenericResponse Success
     * @throws ApiError
     */
    public static getMeSettings1(
app: string,
key: string,
): CancelablePromise<UserSettingEntityResponseGenericResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/me/settings/{app}/{key}',
            path: {
                'app': app,
                'key': key,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param app 
     * @param key 
     * @param requestBody 
     * @returns BooleanGenericResponse Success
     * @throws ApiError
     */
    public static postMeSettings(
app: string,
key: string,
requestBody?: string,
): CancelablePromise<BooleanGenericResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/me/settings/{app}/{key}',
            path: {
                'app': app,
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param app 
     * @param key 
     * @returns BooleanGenericResponse Success
     * @throws ApiError
     */
    public static deleteMeSettings(
app: string,
key: string,
): CancelablePromise<BooleanGenericResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/me/settings/{app}/{key}',
            path: {
                'app': app,
                'key': key,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns BooleanGenericResponse Success
     * @throws ApiError
     */
    public static putMePassword(
requestBody?: MePasswordUpdateData,
): CancelablePromise<BooleanGenericResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/me/password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}