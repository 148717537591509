/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UnitType {
    KILO = 'Kilo',
    LITER = 'Liter',
    PIECE = 'Piece',
    METER = 'Meter',
    VOLUME = 'Volume',
}